<template>
  <div :key="key">
    <div class="container-component">
      <div class="row d-flex flex-column">
        <h2 class="mb-2">
          Welcome {{ user?.firstname + " " + user?.lastname }}!
        </h2>
        <span></span>
      </div>
    </div>
  </div>
</template>
  
  <script>
import store from "@/store";

import User from "@/models/User";

import { uuidv4 } from "@/utils/Uuid";

export default {
  name: "Startseite",
  components: {},
  mixins: [],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {
        return "";
      },
    };
  },
  data() {
    return {
      name: "",
      key: uuidv4(),
    };
  },
  watch: {
    user: function (val) {
      if (val?.role?.id) {
        if (this.user?.role?.id === "1889B2E8093") {
          //ADMIN
          this.$router.push({ name: "companies" });
        } else if (
          this.user?.role?.id === "188AEA750CF" &&
          this.user?.company?.id
        ) {
          //MANUFACTURER
          console.log("MANUFACTURER");
          this.$router.push({
            name: "company",
            params: { id: this.user?.company?.id },
          });
        } else if (this.user?.role?.id === "188AEA7CD44") {
          //PRODUCT DEVELOPER
          this.$router.push({ name: "products" });
        } else if (this.user?.role?.id === "188AEA80E7A") {
          //PRODUCER
          this.$router.push({ name: "products" });
        } else {
          this.$router.push({ name: "profile" });
        }
      } else {
        this.$router.push({ name: "profile" });
      }
    },
  },
  computed: {
    user: {
      get() {
        return this.$store.state.user;
      },
    },
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>
  
  <style lang="scss">
</style>
  